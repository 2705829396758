* {
  font-family: 'Roboto', sans-serif;
}

.App {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background-color:#7c7c7c;
  color: rgb(10, 10, 10);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: 24px;
  margin-top: 24px;
  border-radius: 36px 36px 0px 0px;
}

.main-content {
  width: 90%;
  height:max-content;
  background-color:#61dafb;
  padding-top: 24px;
  margin-top: 24px;
  border-radius: 36px 36px 0px 0px;
}

.card {
  text-align: center;
  background-color: #254d96;
  border-radius: 12px;
  margin: 12px;
  padding: 24px;
}

.scrollableContent {
  height: 100%;
  margin: 0em;
  width:100%;
  overflow-y: auto;
}

.pop-up {
  width: 100%;
  height: 60%;
  filter: drop-shadow(2px 2px 1px #09193177);
  color: rgb(10, 10, 10);
  background-color: #f3f3f3;
  font-size: calc(8px + 2vmin);
  padding-left:60px;
  padding-right:60px;
  margin-left:24px;
  margin-right:24px;
  margin: 0em;
  border-radius:36px;
  overflow-y: scroll;
}

.searchbox {
  width: 90%;
  height: 40px;
  border-radius: 36px;
  padding-left:24px;
  padding-right:24px;
  margin-left:24px;
  margin-right:24px;
}

.pop-up-confirm {
  text-align: center;
}

.pop-up-input-text {
  width: 200px;
}

.ClienteCard {
  color: rgb(10, 10, 10);
  background-color: #f3f3f3;
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 16px;
  margin: 12px;
  border-radius:36px;
  line-height: 200%;
  filter: drop-shadow(2px 2px 1px #09193177);
  width:95%;
  height:80%;
}

.EntregaCard {
  color: rgb(10, 10, 10);
  background-color: #f3f3f3;
  padding-top: 12px;
  padding-bottom: 12px;
  padding: 32px;
  margin: 12px;
  border-radius:36px;
  line-height: 200%;
  filter: drop-shadow(2px 2px 1px #09193177);
  width:95%;
  height:95%
}
.searchbox-container {
  width: 100%;
  display:grid;
  grid-template-columns: 9fr 1fr;
}

.wrapper-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.wrapper {
  display: grid;
  width:100%;
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
}

.triple-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

button {
  border-radius:36px;
  background-color:#e0e0e0;
  color: rgb(10, 10, 10);
  font-weight:bolder;
  padding: 25px 25px 25px 25px;
  filter: drop-shadow(2px 2px 1px #09193177);
  margin: 20px;
}

.selectedButton {
  background-color:#ff4be7;
}

.topbarButton {
  width:100px;
}

input {
  width: 60%;
  height: 40px;
  border-radius: 36px;
  padding-left:24px;
  padding-right:24px;
  margin-left:24px;
  margin-right:24px;
}

textarea {
  width: 90%;
  height: 120px;
  border-radius: 36px;
  padding-left:24px;
  padding-right:24px;
  margin-left:24px;
  margin-right:24px;
}

.deleteButton{
  background-color:#f10707;
}

.fixedButton{
  position: fixed;
  bottom: 5%;
  right: 10%; 
  background-color:#f8f8f8;
  color:rgb(255, 0, 200);
  border-radius: 36px;
  font-size: 2em;
  font-weight: bold;
}

.fixedBackButton{
  position: fixed;
  top: 5%;
  left: 10%; 
  background-color:#f8f8f8;
  color:rgb(15, 15, 15);
  border-radius: 36px;
  font-size: 1em;
  font-weight: bold;
}

.PedidoInfo{
  font-weight: 700;
    font-size: larger;
}

.sheet{
  width:90%;
  background-color: #020c1f;
  border-radius: 36px;
}

@media print {
  * {
    padding:0px;
    margin:0px;
  }
  .App {
    color: rgb(0, 0, 0);
  }

  button {
    visibility: hidden;
    padding: 0px;
    filter: drop-shadow(2px 2px 1px #09193177);
    margin: 0px;
  }

  .pageTitle {
    visibility: hidden;
    padding: 0px;
  }

  .App-header {
    min-height: 0px;
    /* display: flex; */
    /* flex-direction: column; */
    align-items: center;
    color: black;
    padding-top: 0px;
    margin-top: 0px;
  }
  .main-content {
    width: 100%;
    height:max-content;
    padding-top: 0px;
    margin-top: 0px;
    border-radius: 36px 36px 0px 0px;
  }

}

